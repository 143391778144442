import { Variants } from 'framer-motion';
import { SLIDE_UP_BEZIER_CURVE } from '../constants';

export const duration = 1.5;

export const CONTENT_IN_VIEW_BASE_VARIANTS: Variants = {
  visible: {
    opacity: 0.99,
    y: 0,
    transition: {
      duration,
      ease: SLIDE_UP_BEZIER_CURVE,
    },
  },
  hidden: {
    opacity: 0,
    y: 18,
    transition: {
      duration,
      ease: SLIDE_UP_BEZIER_CURVE,
    },
  },
};

export const GRID_CONTAINER_VARIANTS: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 0.99,
    transition: {
      duration,
      delayChildren: 0.25,
      staggerChildren: 0.2,
      ease: SLIDE_UP_BEZIER_CURVE,
    },
  },
};

export const GRID_ITEM_VARIANTS: Variants = {
  visible: {
    opacity: 0.99,
    y: 0,
    transition: {
      duration: 1,
      ease: SLIDE_UP_BEZIER_CURVE,
    },
  },
  hidden: {
    opacity: 0,
    y: 22,
  },
};

export const slideUpVariant = (
  translateY?: string | number,
  transition = {}
): Variants => ({
  visible: {
    translateY: '0%',
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
      ...transition,
    },
  },
  hidden: {
    translateY: translateY || '20%',
    opacity: 0,
  },
});

export const fadeInVariant = (transition = {}): Variants => ({
  visible: {
    opacity: 0.99,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
      ...transition,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: 'easeIn',
    },
  },
});

export const containerVariant = (transition = {}): Variants => ({
  visible: {
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
      delayChildren: 0.1,
      staggerChildren: 0.1,
      ...transition,
    },
  },
  hidden: {
    opacity: 0,
  },
});

export const slideRightVariant = (
  translateX?: string | number,
  transition = {}
): Variants => ({
  visible: {
    opacity: 1,
    translateX: '0%',
    transition: {
      duration: 0.2,
      ease: 'easeIn',
      ...transition
    },
  },
  hidden: {
    translateX: translateX || '100%',
    opacity: 0,
  },
});
