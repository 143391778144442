import React from 'react';
import { m } from 'framer-motion';
import clsx from 'clsx';
import { useInViewAnimation } from '../../hooks';
import * as styles from './SubNavList.module.scss';
import { containerVariant, slideUpVariant } from '../../utils/animation';
import { RouteItem } from './constants';
import { Link } from 'gatsby';

export interface SubNavListProps {
  routes: RouteItem[];
  className?: string;
}

export default function SubNavList({
  routes,
  className
}: SubNavListProps) {
  const { ref: titleRef, control: titleControl } = useInViewAnimation();
  const { ref: containerRef, control: containerControl } = useInViewAnimation();
  
  return (
    <div className={clsx(styles.subnavigation, className)}>
      {routes.map((elem, index) => 
        <div 
          key={String(index)}
          className={styles.subnavigationBox}
        >
          <m.p 
            className={styles.sublistTitle}
            ref={titleRef}
            animate={titleControl}
            initial="hidden"
            variants={slideUpVariant('5%', { delay: 0.4 })}
          >
            {elem.name}
          </m.p>
          <m.ul 
            className={styles.sublist}
            ref={containerRef}
            animate={containerControl}
            initial="hidden"
            variants={containerVariant()}
          >
            {elem.children
              ?.map((item) => 
                <m.li 
                  key={item.id}
                  variants={slideUpVariant('5%')}
                >
                  <Link className={styles.link} to={item.link}>
                    {item.name}
                  </Link>
                </m.li>)
            }
          </m.ul>
        </div>
      )}
    </div>
  )
}
