declare global {
  interface Window {
    ga(...args: unknown[]): void;
  }
}

export const getBoundingClientRect = (element: HTMLElement) => {
  const { top, right, bottom, left, width, height, x, y } =
    element.getBoundingClientRect();
  return { top, right, bottom, left, width, height, x, y };
};

export const isSSR = () =>
  typeof window === 'undefined' && typeof document === 'undefined';

export const ga = (...args: unknown[]) => {
  console.warn('the `ga` function is deprecated, please do not use it');
  return;

  // if (typeof (window as Window).ga === 'function') {
  //   window.ga(...args);
  // }
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const adjustForUTCOffset = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
