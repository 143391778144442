// extracted by mini-css-extract-plugin
export var activeLink = "HeaderDesktopMenu-module--active-link--46147";
export var close = "HeaderDesktopMenu-module--close--0669c";
export var closeLight = "HeaderDesktopMenu-module--close-light--616e7";
export var drop = "HeaderDesktopMenu-module--drop--2d3ad";
export var item = "HeaderDesktopMenu-module--item--f16a6";
export var itemOpen = "HeaderDesktopMenu-module--item-open--5e9d7";
export var link = "HeaderDesktopMenu-module--link--59a11";
export var linkLight = "HeaderDesktopMenu-module--link-light--cd9ac";
export var menuItem = "HeaderDesktopMenu-module--menu-item--1602f";
export var open = "HeaderDesktopMenu-module--open--6973b";
export var root = "HeaderDesktopMenu-module--root--44771";
export var wrap = "HeaderDesktopMenu-module--wrap--57a9c";