import React from 'react';
import { m, Variants } from 'framer-motion';
import * as styles from './ContactUsBanner.module.scss';
import { useInViewAnimation } from '../../hooks';

export interface ContactUsBannerProps {
  transition?: Variants;
}

export default function ContactUsBanner({ 
  transition
}: ContactUsBannerProps) {
  const { ref, control } = useInViewAnimation();
  
  return (
    <m.div
      className={styles.root}
      ref={ref}
      animate={control}
      initial="hidden"
      variants={transition}
    >
      <div
        className={styles.title}
      >
        Book a meeting to learn more about 
        our staffing services
      </div>
      <a
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://meetings.hubspot.com/oleg-panchenko"
      >
        Book now
      </a>
    </m.div>
  )
}
