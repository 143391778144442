import { useState, useEffect, useRef } from 'react';

export default function useScrollPercent(
  treshold = 0,
  isActive = true
): [React.RefObject<HTMLElement>, number] {
  const elementRef = useRef<HTMLElement>(null);
  const [scrolledPercent, setScrolledPercent] = useState(0);

  useEffect(() => {
    if (!isActive) {
      return undefined;
    }
    const element = elementRef.current;
    if (!element) {
      return undefined;
    }
    const windowScrollListener = () => {
      const scrolledToScreenBottom = window.pageYOffset + window.innerHeight;
      const sectionBottomOffset = element.offsetTop + element.offsetHeight;
      const sectionHeight = element.offsetHeight;
      const scrollTopRatio =
        (scrolledToScreenBottom -
          sectionBottomOffset +
          sectionHeight +
          treshold) /
        sectionHeight;
      const scrollTopPercent = Math.min(Math.max(scrollTopRatio, 0), 1); // clamp between 0 and 1
      setScrolledPercent(scrollTopPercent);
    };
    windowScrollListener();
    window.addEventListener('scroll', windowScrollListener, false);
    return () => window.removeEventListener('scroll', windowScrollListener);
  }, [isActive]);

  return [elementRef, scrolledPercent];
}
