import { m, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

export interface UseInViewAnimationOptions extends IntersectionOptions {
  waitBeforeStart?: number;
}

export default function useInViewAnimation(
  options?: UseInViewAnimationOptions
) {
  const control = useAnimation();
  const { waitBeforeStart = 0, ...otherOptions } =
    options || ({} as UseInViewAnimationOptions);

  const { ref, inView } = useInView({
    threshold: 0.12,
    triggerOnce: true,
    ...otherOptions,
  });

  useEffect(() => {
    if (inView) {
      const timeoutId = setTimeout(
        () => control.start('visible'),
        waitBeforeStart * 1000
      );

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return () => undefined;
  }, [control, inView, waitBeforeStart]);

  return { ref, control, m };
}
