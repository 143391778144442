import uniqueId from 'lodash/uniqueId';

export interface RouteBase {
  id: string;
  name: string;
  link: string;
}

export interface RouteItem extends RouteBase {
  children?: RouteBase[];
}

export const UA_PHONE_NUMBER = '+380632522221';
export const UA_FORMATTED_PHONE_NUMBER = '+38 063 252 22 21';

export const PL_PHONE_NUMBER = '+48795991686';
export const PL_FORMATTED_PHONE_NUMBER = '+48 795 991 686';

export const EMAIL = 'info@freysoft.com';

export const VIBER_URL =
  'https://wa.me/380632522221?text=Tell%20me%20more%20about%20your%20services';
export const TG_URL =
  // eslint-disable-next-line max-len
  'https://telegram.me/freysoft?text=Tell%20me%20more%20about%20your%20services';

export const routes: RouteItem[] = [
  // {
  //   id: uniqueId(),
  //   name: 'Company',
  //   link: '/',
  // },
  {
    id: uniqueId(),
    name: 'Careers',
    link: '/careers/',
  },
  {
    id: uniqueId(),
    name: 'Cases',
    link: '/cases/',
  },
  {
    id: uniqueId(),
    name: 'Blog',
    link: '/blog/',
  },
];

export const expertiseRoute: RouteItem = {
  id: uniqueId(),
  name: 'Expertise',
  link: '/expertise/',
  children: [
    {
      id: uniqueId(),
      name: 'Front end development',
      link: '/expertise/frontend-development-services/',
    },
    {
      id: uniqueId(),
      name: 'Back end development',
      link: '/expertise/backend-development-services/',
    },
    {
      id: uniqueId(),
      name: 'Full stack development',
      link: '/expertise/fullstack-development-services/',
    },
    {
      id: uniqueId(),
      name: 'Cloud First',
      link: '/expertise/cloud-first-development-services/',
    },
    {
      id: uniqueId(),
      name: 'Mobile App Development',
      link: '/expertise/mobile-app-development-services/',
    },
    {
      id: uniqueId(),
      name: 'Web development',
      link: '/expertise/web-development-services/',
    },
    {
      id: uniqueId(),
      name: 'UI/UX Design',
      link: '/expertise/ui-ux-design/',
    },
  ],
};

export const servicesRoute: RouteItem = {
  id: uniqueId(),
  name: 'Services',
  link: '/services/',
  children: [
    {
      id: uniqueId(),
      name: 'Third party integrations',
      link: '/services/third-party-integrations/',
    },
    {
      id: uniqueId(),
      name: 'Application development and maintenance',
      link: '/services/application-development-and-maintenance/',
    },
    {
      id: uniqueId(),
      name: 'Product development',
      link: '/services/dpoduct-development-service/',
    },
    {
      id: uniqueId(),
      name: 'Legacy Transformation, Cloud Migration',
      link: '/services/legacy-transformation/',
    },
    {
      id: uniqueId(),
      name: 'Delivery team as a subscription service',
      link: '/services/delivery-team-as-a-subscription/',
    },
    {
      id: uniqueId(),
      name: 'Staff augmentation',
      link: '/services/staff-augmentation/',
    },
  ],
};

export const pricingRoute: RouteItem = {
  id: uniqueId(),
  name: 'Pricing',
  link: '/pricing/',
  children: [
    {
      id: uniqueId(),
      name: 'Get your project estimation',
      link: '/pricing/project-estimation/',
    },
    {
      id: uniqueId(),
      name: 'Get rates for your team augmentation',
      link: '/pricing/team-augmentation/',
    },
  ],
};

export const industriesRoute: RouteItem = {
  id: uniqueId(),
  name: 'Industries',
  link: '/industries/',
  children: [
    {
      id: uniqueId(),
      name: 'FinTech',
      link: '/industries/fin-tech/',
    },
    {
      id: uniqueId(),
      name: 'Healthcare',
      link: '/industries/healthcare/',
    },
    {
      id: uniqueId(),
      name: 'Ecommerce',
      link: '/industries/e-commerce/',
    },
    {
      id: uniqueId(),
      name: 'Telecom',
      link: '/industries/telecom/',
    },
    {
      id: uniqueId(),
      name: 'Information technology',
      link: '/industries/information-technology/',
    },
    {
      id: uniqueId(),
      name: 'Manufacturing',
      link: '/industries/manufacturing/',
    },
    {
      id: uniqueId(),
      name: 'Hospitality & Leisure',
      link: '/industries/hospitality-and-leisure/',
    },
    {
      id: uniqueId(),
      name: 'Logistics',
      link: '/industries/logistics/',
    },
    {
      id: uniqueId(),
      name: 'Insurance',
      link: '/industries/insurance/',
    },
  ],
};
