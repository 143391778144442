exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-cases-e-commerce-platform-perfume-retailer-tsx": () => import("./../../../src/pages/cases/e-commerce-platform-perfume-retailer.tsx" /* webpackChunkName: "component---src-pages-cases-e-commerce-platform-perfume-retailer-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-expertise-backend-development-services-tsx": () => import("./../../../src/pages/expertise/backend-development-services.tsx" /* webpackChunkName: "component---src-pages-expertise-backend-development-services-tsx" */),
  "component---src-pages-expertise-cloud-first-development-services-tsx": () => import("./../../../src/pages/expertise/cloud-first-development-services.tsx" /* webpackChunkName: "component---src-pages-expertise-cloud-first-development-services-tsx" */),
  "component---src-pages-expertise-frontend-development-services-tsx": () => import("./../../../src/pages/expertise/frontend-development-services.tsx" /* webpackChunkName: "component---src-pages-expertise-frontend-development-services-tsx" */),
  "component---src-pages-expertise-fullstack-development-services-tsx": () => import("./../../../src/pages/expertise/fullstack-development-services.tsx" /* webpackChunkName: "component---src-pages-expertise-fullstack-development-services-tsx" */),
  "component---src-pages-expertise-mobile-app-development-services-tsx": () => import("./../../../src/pages/expertise/mobile-app-development-services.tsx" /* webpackChunkName: "component---src-pages-expertise-mobile-app-development-services-tsx" */),
  "component---src-pages-expertise-ui-ux-design-tsx": () => import("./../../../src/pages/expertise/ui-ux-design.tsx" /* webpackChunkName: "component---src-pages-expertise-ui-ux-design-tsx" */),
  "component---src-pages-expertise-web-development-services-tsx": () => import("./../../../src/pages/expertise/web-development-services.tsx" /* webpackChunkName: "component---src-pages-expertise-web-development-services-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-application-development-and-maintenance-tsx": () => import("./../../../src/pages/services/application-development-and-maintenance.tsx" /* webpackChunkName: "component---src-pages-services-application-development-and-maintenance-tsx" */),
  "component---src-pages-services-delivery-team-as-a-subscription-tsx": () => import("./../../../src/pages/services/delivery-team-as-a-subscription.tsx" /* webpackChunkName: "component---src-pages-services-delivery-team-as-a-subscription-tsx" */),
  "component---src-pages-services-legacy-transformation-tsx": () => import("./../../../src/pages/services/legacy-transformation.tsx" /* webpackChunkName: "component---src-pages-services-legacy-transformation-tsx" */),
  "component---src-pages-services-product-development-service-tsx": () => import("./../../../src/pages/services/product-development-service.tsx" /* webpackChunkName: "component---src-pages-services-product-development-service-tsx" */),
  "component---src-pages-services-staff-augmentation-tsx": () => import("./../../../src/pages/services/staff-augmentation.tsx" /* webpackChunkName: "component---src-pages-services-staff-augmentation-tsx" */),
  "component---src-pages-services-third-party-integrations-tsx": () => import("./../../../src/pages/services/third-party-integrations.tsx" /* webpackChunkName: "component---src-pages-services-third-party-integrations-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-case-tsx": () => import("./../../../src/templates/case.tsx" /* webpackChunkName: "component---src-templates-case-tsx" */),
  "component---src-templates-cases-tsx": () => import("./../../../src/templates/cases.tsx" /* webpackChunkName: "component---src-templates-cases-tsx" */),
  "component---src-templates-industries-tsx": () => import("./../../../src/templates/industries.tsx" /* webpackChunkName: "component---src-templates-industries-tsx" */),
  "component---src-templates-job-post-tsx": () => import("./../../../src/templates/job-post.tsx" /* webpackChunkName: "component---src-templates-job-post-tsx" */),
  "component---src-templates-wp-case-tsx": () => import("./../../../src/templates/wp-case.tsx" /* webpackChunkName: "component---src-templates-wp-case-tsx" */)
}

