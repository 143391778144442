import React from 'react';
import { Modal, ModalProps } from '@mui/material';
import { m } from 'framer-motion';
import clsx from 'clsx';
import useInViewAnimation from '../../hooks/useInViewAnimation';
import { fadeInVariant } from '../../utils/animation';
import { CloseModalIcon } from '../../icons';
import * as styles from './ThanksModal.module.scss';
import doneIcon from '../../images/done.svg';

export type ContactUsModalProps = Omit<ModalProps, 'children'>;

export default function ThanksModal({
  onClose,
  ...otherProps
}: ContactUsModalProps) {
  const { ref: iconRef, control: iconControl } = useInViewAnimation();
  const { ref: headingRef, control: headingControl } = useInViewAnimation();
  const { ref: textRef, control: textControl } = useInViewAnimation();
  const { ref: desireRef, control: desireControl } = useInViewAnimation();

  return (
    <Modal onClose={onClose} {...otherProps} className={styles.modalRoot}>
      <div className={styles.wrapper}>
        <div className={styles.modal}>
          <m.img 
            className={styles.icon} 
            src={doneIcon} 
            alt="check icon"
            ref={iconRef}
            animate={iconControl}
            initial="hidden"
            variants={fadeInVariant()}
          />
          <m.h2
            className={clsx(styles.title)}
            ref={headingRef}
            animate={headingControl}
            initial="hidden"
            variants={fadeInVariant()}

          >
            Thanks for submitting our form!
          </m.h2>
          <m.p 
            className={styles.text}
            ref={textRef}
            animate={textControl}
            initial="hidden"
            variants={fadeInVariant()}

          >
            We’re honored to have a request from you. We will process 
            it as soon as we can and contact you in three working days!
          </m.p>
          <m.p 
            className={styles.desire}
            ref={desireRef}
            animate={desireControl}
            initial="hidden"
            variants={fadeInVariant()}

          >
            All the best! Your FreySoft team
          </m.p>
          <button
            type="button"
            className={styles.modalClose}
            onClick={() => onClose?.({}, 'backdropClick')}
          >
            <CloseModalIcon />
          </button>
        </div>
      </div>
    </Modal>
  );
}
