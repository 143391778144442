import clsx from 'clsx';
import React from 'react';
import * as styles from './Container.module.scss';

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  fixed?: boolean;
  wide?: boolean;
  slim?: boolean;
};

function Container(
  { children, className, fixed, wide, slim, ...props }: ContainerProps,
  ref: React.LegacyRef<HTMLDivElement> | undefined
) {
  return (
    <div
      ref={ref}
      className={clsx(
        styles.root,
        { [styles.fixed]: fixed, [styles.wide]: wide, [styles.slim]: slim },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export default React.forwardRef(Container);
