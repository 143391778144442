import React from 'react';
import { m } from 'framer-motion';
import { Grid } from '@mui/material';
import Container from '../Container';
import * as styles from './FooterNavigation.module.scss';
import logoImg from '../../../shared-ui/images/freysoft-logo-text.svg';
import arrowIcon from '../../../shared-ui/images/arrow-top.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import { EMAIL, 
  expertiseRoute,
  PL_PHONE_NUMBER,
  PL_FORMATTED_PHONE_NUMBER,
  UA_PHONE_NUMBER,
  UA_FORMATTED_PHONE_NUMBER, 
  industriesRoute, 
  // pricingRoute,
  routes, 
  servicesRoute, 
  TG_URL, 
  VIBER_URL 
} from './constants';
import { useInViewAnimation } from '../../hooks';
import { containerVariant, slideUpVariant } from '../../utils/animation';
import { scrollToTop } from '../../utils';
import SubNavList from './SubNavList';

export default function FooterNavigation() {
  const { ref: logoRef, control: logoControl } = useInViewAnimation();
  const { ref: headingRef, control: headingControl } = useInViewAnimation();
  const { ref: containerRef, control: containerControl } = useInViewAnimation();
  const { ref: subtitleRef, control: subtitleControl } = useInViewAnimation();
  const { ref: telRef, control: telControl } = useInViewAnimation();
  const { ref: mailRef, control: mailControl } = useInViewAnimation();
  const { ref: buttonRef, control: buttonControl } = useInViewAnimation();

  return (
    <div className={styles.root}>
      <Container>
        <Grid container spacing={{ xs: 4, xl: 0 }}>
          <Grid item xs={12} md={4} lg={3}>
            <m.img 
              className={styles.logo} 
              src={logoImg} 
              alt="Freysoft" 
              width="146" 
              height="40"
              ref={logoRef}
              animate={logoControl}
              initial="hidden"
              variants={slideUpVariant('5%')}
            />
            <m.p 
              className={styles.text}
              ref={headingRef}
              animate={headingControl}
              initial="hidden"
              variants={slideUpVariant('5%', { delay: 0.2 })}
            >
              FreySoft is a custom software development and consulting company 
              from Kyiv, Ukraine that builds dedicated teams skilled in JavaScript, 
              .NET, PHP, JAVA, Node.js, Angular, React, AWS, Serverless.
            </m.p>
          </Grid>
          <Grid item xs={12} md={8} lg={9} xl={6}>
            <div className={styles.wrapper}>
              <div className={styles.navigation}>
                <m.ul 
                  className={styles.list}
                  ref={containerRef}
                  animate={containerControl}
                  initial="hidden"
                  variants={containerVariant()}
                >
                  {routes
                    .map((item) =>  (
                      <m.li 
                        key={item.id}
                        variants={slideUpVariant('5%')}
                      >
                        <a className={styles.link} href={item.link}>
                          {item.name}
                        </a>
                      </m.li>
                    ))
                  }
                </m.ul>
                <SubNavList 
                  routes={[/* pricingRoute, */ industriesRoute]}
                  className={styles.subnavigationMobile}
                />
              </div>
              <div className={styles.subnavigationWrapper}>
                {/* <SubNavList 
                  routes={[pricingRoute]}
                  className={styles.subnavigationDesktop}
                /> */}
                <SubNavList 
                  routes={[expertiseRoute, servicesRoute]}
                />
                <SubNavList 
                  routes={[/* pricingRoute, */ industriesRoute]}
                  className={styles.subnavigationTablet}
                />
                <SubNavList 
                  routes={[industriesRoute]}
                  className={styles.subnavigationDesktop}
                />
              </div>
            </div>
          </Grid>
          <Grid 
            className={styles.contacts} 
            container 
            item 
            xs={12} 
            xl={2} 
            alignContent="flex-start"
          >
            <Grid item md={6} xl={12}>
              <m.p 
                className={styles.contactTitle}
                ref={subtitleRef}
                animate={subtitleControl}
                initial="hidden"
                variants={slideUpVariant('5%', { delay: 0.4 })}
              >
                Get in touch
              </m.p>
              <m.a 
                className={styles.phone} 
                href={`tel:${PL_PHONE_NUMBER}`}
                ref={telRef}
                animate={telControl}
                initial="hidden"
                variants={slideUpVariant('5%', { delay: 0.5 })}
              >
                {PL_FORMATTED_PHONE_NUMBER}
              </m.a>
              <m.a 
                className={styles.phone} 
                href={`tel:${UA_PHONE_NUMBER}`}
                ref={telRef}
                animate={telControl}
                initial="hidden"
                variants={slideUpVariant('5%', { delay: 0.6 })}
              >
                {UA_FORMATTED_PHONE_NUMBER}
              </m.a>
              <m.a 
                className={styles.email} 
                href={`mailto:${EMAIL}`}
                ref={mailRef}
                animate={mailControl}
                initial="hidden"
                variants={slideUpVariant('5%', { delay: 0.7 })}
              >
                {EMAIL}
              </m.a>
            </Grid>
            <Grid className={styles.links} container item md={6} xl={12}>
              <m.div 
                className={styles.linksWrapper}
                ref={containerRef}
                animate={containerControl}
                initial="hidden"
                variants={containerVariant()}
              >
                <m.div variants={slideUpVariant('5%', { delay: 0.8 })}>
                  <a
                    className={styles.icon}
                    href={VIBER_URL}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <WhatsAppIcon fontSize="inherit" />
                  </a>
                </m.div>
                <m.div variants={slideUpVariant('5%', { delay: 0.9 })}>
                  <a
                    className={styles.icon}
                    href={TG_URL}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <TelegramIcon fontSize="inherit" />
                  </a>
                </m.div>
              </m.div>
              <m.button 
                className={styles.button} 
                type="button" 
                aria-label='scroll to top'
                onClick={scrollToTop}
                ref={buttonRef}
                animate={buttonControl}
                initial="hidden"
                variants={slideUpVariant('5%', { delay: 1 })}
              >
                <img src={arrowIcon} alt="arrow" />
              </m.button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
