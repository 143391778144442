// extracted by mini-css-extract-plugin
export var button = "HeaderMobileMenuItem-module--button--29c05";
export var hide = "HeaderMobileMenuItem-module--hide--fca0f";
export var highlight = "HeaderMobileMenuItem-module--highlight--65996";
export var line = "HeaderMobileMenuItem-module--line--051bc";
export var link = "HeaderMobileMenuItem-module--link--e8501";
export var linkLight = "HeaderMobileMenuItem-module--link-light--af8ed";
export var menuItem = "HeaderMobileMenuItem-module--menu-item--845d8";
export var root = "HeaderMobileMenuItem-module--root--68909";
export var separator = "HeaderMobileMenuItem-module--separator--d1bed";
export var separatorContainer = "HeaderMobileMenuItem-module--separator-container--ddc67";
export var title = "HeaderMobileMenuItem-module--title--fd2d3";