// extracted by mini-css-extract-plugin
export var button = "FooterNavigation-module--button--779ff";
export var contactTitle = "FooterNavigation-module--contactTitle--04003";
export var contacts = "FooterNavigation-module--contacts--35a88";
export var email = "FooterNavigation-module--email--45efa";
export var icon = "FooterNavigation-module--icon--c43a5";
export var link = "FooterNavigation-module--link--1c511";
export var links = "FooterNavigation-module--links--1eed4";
export var linksWrapper = "FooterNavigation-module--linksWrapper--4638f";
export var list = "FooterNavigation-module--list--a7ac1";
export var logo = "FooterNavigation-module--logo--a5167";
export var navigation = "FooterNavigation-module--navigation--3a55b";
export var phone = "FooterNavigation-module--phone--a4351";
export var root = "FooterNavigation-module--root--854dd";
export var subnavigationDesktop = "FooterNavigation-module--subnavigation-desktop--8a2ab";
export var subnavigationMobile = "FooterNavigation-module--subnavigation-mobile--e9535";
export var subnavigationTablet = "FooterNavigation-module--subnavigation-tablet--ea717";
export var subnavigationWrapper = "FooterNavigation-module--subnavigation-wrapper--1b026";
export var text = "FooterNavigation-module--text--dcdb8";
export var wrapper = "FooterNavigation-module--wrapper--4f92e";