// extracted by mini-css-extract-plugin
export var attach = "ContactUsForm-module--attach--63bd5";
export var attachButton = "ContactUsForm-module--attach-button--fd137";
export var attachField = "ContactUsForm-module--attach-field--0739d";
export var attachIcon = "ContactUsForm-module--attach-icon--03b7b";
export var checkboxContainer = "ContactUsForm-module--checkbox-container--b20a2";
export var checkboxDescription = "ContactUsForm-module--checkbox-description--d7a84";
export var checkboxDescriptionDark = "ContactUsForm-module--checkbox-description-dark--dde15";
export var checkboxField = "ContactUsForm-module--checkbox-field--80074";
export var checkboxIcon = "ContactUsForm-module--checkbox-icon--e5c44";
export var checkboxIconDark = "ContactUsForm-module--checkbox-icon-dark--d857c";
export var gridItem = "ContactUsForm-module--gridItem--16695";
export var input = "ContactUsForm-module--input--90e56";
export var inputField = "ContactUsForm-module--inputField--8f541";
export var send = "ContactUsForm-module--send--f7217";