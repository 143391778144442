import * as yup from 'yup';
import { UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateFileExtensions, validateFileSize } from '../../utils';

export enum ContactUsFormFieldNames {
  FULL_NAME = 'fullName',
  EMAIL = 'email',
  PHONE = 'phone',
  COMPANY = 'company',
  MESSAGE = 'message',
  COUNTRY = 'country',
  SUBSCRIBE = 'subscribe',
  ATTACHMENT = 'attachment',
}

export interface ContactUsFormFieldValues {
  [ContactUsFormFieldNames.FULL_NAME]: string;
  [ContactUsFormFieldNames.EMAIL]: string;
  [ContactUsFormFieldNames.PHONE]: string;
  [ContactUsFormFieldNames.COMPANY]: string;
  [ContactUsFormFieldNames.MESSAGE]: string;
  [ContactUsFormFieldNames.COUNTRY]: string;
  [ContactUsFormFieldNames.SUBSCRIBE]: boolean;
  [ContactUsFormFieldNames.ATTACHMENT]: FileList | null;
}

export const validationSchema = yup.object().shape({
  [ContactUsFormFieldNames.FULL_NAME]: yup
    .string()
    .max(80)
    .required('Name is required'),
  [ContactUsFormFieldNames.PHONE]: yup
    .string()
    .matches(/^[+]?\d+$/, 'Phone number is not valid')
    .min(10, 'Phone number must be 10-15 digits')
    .max(15, 'Phone number must be 10-15 digits')
    .required('Phone number is required'),
  [ContactUsFormFieldNames.EMAIL]: yup
    .string()
    .email('Email must be a valid email')
    .required('Email is required'),
  [ContactUsFormFieldNames.COMPANY]: yup
    .string()
    .max(25)
    .required('Company is required'),
  [ContactUsFormFieldNames.MESSAGE]: yup
    .string()
    .max(150)
    .required('Message is required'),
  [ContactUsFormFieldNames.COUNTRY]: yup
    .string()
    .max(50)
    .required('Country cant be blank. Please, select your country'),
  [ContactUsFormFieldNames.SUBSCRIBE]: yup.boolean().notRequired(),
  [ContactUsFormFieldNames.ATTACHMENT]: yup
    .mixed()
    .test('type', 'This format is not supported', (files) =>
      validateFileExtensions(files)
    )
    .test('size', 'File size should be less than 5 MB', (files) =>
      validateFileSize(files)
    )
    .nullable()
    .optional(),
});

export const defaultValues = {
  [ContactUsFormFieldNames.FULL_NAME]: '',
  [ContactUsFormFieldNames.EMAIL]: '',
  [ContactUsFormFieldNames.PHONE]: '',
  [ContactUsFormFieldNames.COMPANY]: '',
  [ContactUsFormFieldNames.MESSAGE]: '',
  [ContactUsFormFieldNames.COUNTRY]: '',
  [ContactUsFormFieldNames.SUBSCRIBE]: false,
  [ContactUsFormFieldNames.ATTACHMENT]: null,
} as ContactUsFormFieldValues;

const config: UseFormProps<ContactUsFormFieldValues> = {
  mode: 'onChange',
  reValidateMode: 'onChange',
  resolver: yupResolver(validationSchema),
  defaultValues,
};

export default config;
