import React from 'react';
import { Link } from 'gatsby';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { format } from 'date-fns';
import Container from '../Container';
import * as styles from './Footer.module.scss';
import { ga } from '../../utils';
import certificatLogo from '../../images/certificat-logo.svg';
import linkedinIcon from '../../images/linkedin_ic.svg';
import facebookIcon from '../../images/facebook_ic.svg';
import twitterIcon from '../../images/twitter_ic.svg';
import linkedinGreyIcon from '../../images/linkedin-grey.svg';
import facebookGreyIcon from '../../images/fb-grey.svg';
import twitterGreyIcon from '../../images/twitter-grey.svg';
import FooterNavigation from '../FooterNavigation';

const info = [
  {
    id: 1,
    title: 'Cookie policy',
    link: '/cookie-policy/',
    onClick: () => {
      // ga('send', 'event', 'links', 'click', 'cookie-policy');
    },
  },
  {
    id: 2,
    title: 'Privacy policy',
    link: '/privacy-policy/',
    onClick: () => {
      // ga('send', 'event', 'links', 'click', 'privacy-policy');
    },
  },
];

type Info = {
  title: string;
  link: string;
  id: number;
  onClick?: () => void;
};

type FooterProps = {
  variant?: string;
};

export default function Footer({ variant }: FooterProps) {
  return (
    <div
      className={clsx(styles.root, {
        [styles.dark]: variant === 'dark',
      })}
    >
      <FooterNavigation />
      <Container className={styles.container}>
        <Grid container alignItems="center">
          <Grid item md={6} xs={12}>
            <div className={styles.copyrightWrap}>
              <span
                className={clsx(styles.copyright, {
                  [styles.copyrightDark]: variant === 'dark',
                })}
              >
                © 2018-{format(new Date(), 'Y')} FREYSOFT. All rights reserved
              </span>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={styles.links}>
              <div
                className={clsx(styles.infoWrap, {
                  [styles.infoWrapDark]: variant === 'dark',
                })}
              >
                <div
                  className={clsx(styles.infoLinks, {
                    [styles.infoLinksDark]: variant === 'dark',
                  })}
                >
                  {info.map((item: Info) => (
                    <Link
                      key={item.id}
                      className={clsx(styles.infoItem, {
                        [styles.infoItemDark]: variant === 'dark',
                      })}
                      onClick={item.onClick}
                      to={item.link}
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
              </div>
              <div
                className={clsx({
                  [styles.mediaWrapDark]: variant === 'dark',
                })}
              >
                <Grid container justifyContent="center" spacing={0}>
                  <Grid item>
                    <a
                      className={styles.mediaIcons}
                      href="https://www.linkedin.com/company/frey-soft/"
                      onClick={() => {
                        // ga('send', 'event', 'links', 'click', 'linkedin');
                      }}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="linkedin"
                        src={
                          variant === 'dark' ? linkedinGreyIcon : linkedinIcon
                        }
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      className={styles.mediaIcons}
                      href="https://www.facebook.com/freysoft/"
                      onClick={() => {
                        // ga('send', 'event', 'links', 'click', 'facebook');
                      }}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="facebook"
                        src={
                          variant === 'dark' ? facebookGreyIcon : facebookIcon
                        }
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      className={styles.mediaIcons}
                      href="https://twitter.com/FreySoftInc"
                      onClick={() => {
                        // ga('send', 'event', 'links', 'click', 'twitter');
                      }}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="twitter"
                        src={variant === 'dark' ? twitterGreyIcon : twitterIcon}
                      />
                    </a>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
