// extracted by mini-css-extract-plugin
export var cookieBtn = "SnackContent-module--cookie-btn--28d1a";
export var cookieImage = "SnackContent-module--cookie-image--369c9";
export var cookieText = "SnackContent-module--cookie-text--07014";
export var cookies = "SnackContent-module--cookies--d79c8";
export var fade = "SnackContent-module--fade--e6528";
export var gridContainer = "SnackContent-module--grid-container--70ebc";
export var gridContainerCookie = "SnackContent-module--grid-container-cookie--3e972";
export var snack = "SnackContent-module--snack--b9481";
export var snackClose = "SnackContent-module--snack-close--f3b27";
export var snackContent = "SnackContent-module--snack-content--1d7ae";