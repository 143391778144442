// extracted by mini-css-extract-plugin
export var back = "HeaderMobileMenu-module--back--24d40";
export var backArrow = "HeaderMobileMenu-module--back-arrow--3d1ec";
export var backButton = "HeaderMobileMenu-module--back-button--7486d";
export var backText = "HeaderMobileMenu-module--back-text--65792";
export var burger = "HeaderMobileMenu-module--burger--cc4b0";
export var close = "HeaderMobileMenu-module--close--4a146";
export var closeLight = "HeaderMobileMenu-module--close-light--98627";
export var list = "HeaderMobileMenu-module--list--ae251";
export var navIcon = "HeaderMobileMenu-module--nav-icon--d95bb";
export var paper = "HeaderMobileMenu-module--paper--94378";
export var paperPrimary = "HeaderMobileMenu-module--paper-primary--59de2";
export var paperSecondary = "HeaderMobileMenu-module--paper-secondary--2982f";