import axios, { AxiosError } from 'axios';
import { HUBSPOT_PORTAL_ID, HUBSPOT_URL } from '../constants';
import { ExtendedAxiosError } from '../types';

const legalConsentOptions = {
  consent: {
    // Include this object when GDPR options are enabled
    consentToProcess: true,
    // eslint-disable-next-line max-len
    text: 'I agree to allow Example Company to store and process my personal data.',
    communications: [
      {
        value: true,
        subscriptionTypeId: 999,
        // eslint-disable-next-line max-len
        text: 'I agree to receive marketing communications from Example Company.',
      },
    ],
  },
};

const handleError = (error: ExtendedAxiosError) => {
  if (error?.response?.data) {
    const { errors, message: errorMessage } = error.response.data;

    if (errors.length > 0) {
      error.errors = errors;
      throw error;
    }

    if (errorMessage) {
      throw new Error(errorMessage);
    }
  } else {
    throw error;
  }
};

/**
 * Uploads file to the HubSpot file manager.
 * @see https://legacydocs.hubspot.com/docs/methods/files/v3/upload_new_file
 */
export const uploadFileToHubSpot = async (file: File) => {
  try {
    const data = new FormData();
    data.append('file', file);

    const response = await axios.post('/api/hubspot/file-upload.php', data);

    if (!(response.data.objects && response.data.objects.length > 0)) {
      throw new Error('Something went wrong...');
    }

    return response.data.objects;
  } catch (e) {
    const error = e as Error;
    throw new Error(error.message);
  }
};

type HubspotJobApplicationData = {
  full_name: string;
  email: string;
  message: string;
  resume: FileList | null;
};

export const sendHubspotJobApplication = async ({
  full_name: fullName,
  email,
  message,
  resume,
}: HubspotJobApplicationData) => {
  try {
    const formId = 'd6af6214-c9ac-4066-b735-edc0b6113c1a';
    const fields = [];

    if (email) {
      fields.push({
        name: 'email',
        value: email,
      });
    }

    if (fullName) {
      fields.push({
        name: 'full_name',
        value: fullName,
      });
    }

    if (message) {
      fields.push({
        name: 'message',
        value: message,
      });
    }

    let resumeRemoteUrl = '';

    if (resume && resume.length > 0) {
      const file = resume[0];
      const objects = await uploadFileToHubSpot(file);

      if (objects) {
        [{ url: resumeRemoteUrl }] = objects;
      }
    }

    if (resumeRemoteUrl) {
      fields.push({
        name: 'resume',
        value: resumeRemoteUrl,
      });
    }

    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${HUBSPOT_URL}/${HUBSPOT_PORTAL_ID}/${formId}`,
      {
        fields,
        legalConsentOptions,
      }
    );

    return response;
  } catch (e) {
    const error = e as AxiosError;
    return handleError(error);
  }
};

type ContactUsFormData = {
  email: string;
  name: string;
  message: string;
  subscribe: boolean;
  phone: string;
  company: string;
  country: string;
  attachment?: File;
};

export const sendContactUsForm = async ({
  email,
  name,
  phone,
  company,
  country,
  message,
  subscribe,
  attachment,
}: ContactUsFormData) => {
  try {
    const formId = '471c91e7-e5cb-4004-bd71-986371c36082';
    const fields = [];

    if (email) {
      fields.push({
        name: 'email',
        value: email,
      });
    }

    if (name) {
      fields.push({
        name: 'full_name',
        value: name,
      });
    }

    if (phone) {
      fields.push({
        name: 'phone',
        value: phone,
      });
    }

    if (country) {
      fields.push({
        name: 'country',
        value: country,
      });
    }

    if (company) {
      fields.push({
        name: 'company',
        value: company,
      });
    }

    if (message) {
      fields.push({
        name: 'message',
        value: message,
      });
    }

    if (typeof subscribe === 'boolean') {
      fields.push({
        name: 'subscribe',
        value: subscribe,
      });
    }

    let attachmentRemoteUrl = '';

    if (attachment) {
      const objects = await uploadFileToHubSpot(attachment);

      if (objects) {
        [{ url: attachmentRemoteUrl }] = objects;
      }
    }

    if (attachmentRemoteUrl) {
      fields.push({
        name: 'attachment',
        value: attachmentRemoteUrl,
      });
    }

    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${HUBSPOT_URL}/${HUBSPOT_PORTAL_ID}/${formId}`,
      {
        fields,
        legalConsentOptions,
      }
    );

    return response;
  } catch (e) {
    const error = e as AxiosError;
    return handleError(error);
  }
};

interface sendFormField {
  name: string;
  value: string;
}

export const sendForm = async (formId: string, fields: sendFormField[]) => {
  try {
    const response = await axios.post(
      // eslint-disable-next-line max-len
      `${HUBSPOT_URL}/${HUBSPOT_PORTAL_ID}/${formId}`,
      {
        fields,
        legalConsentOptions,
      }
    );

    return response;
  } catch (e) {
    const error = e as AxiosError;
    return handleError(error);
  }
};

type GreenhouseData = {
  id?: string | number;
  email: string;
  first_name: string;
  last_name: string;
  message?: string;
  phone?: string;
  resume: FileList | null;
};

export const sendGreenhouseJobApplication = (data: GreenhouseData) => {
  const formData = new FormData();
  const uri = '/api/greenhouse/job-application.php';

  if (data.id) {
    formData.append('id', String(data.id));
  }

  if (data.resume) {
    formData.append('resume', data.resume[0]);
  }

  formData.append('first_name', data.first_name);
  formData.append('last_name', data.last_name);
  formData.append('email', data.email);

  if (data.message) {
    formData.append('message', data.message);
  }

  if (data.phone) {
    formData.append('phone', data.phone);
  }

  return axios.post(uri, formData);
};
