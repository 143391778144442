import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LazyMotion, domAnimation } from 'framer-motion';
import { SnackbarProvider } from 'notistack';
import clsx from 'clsx';
import ModalProvider from 'mui-modal-provider';

import SnackContent from '../SnackContent';
import Header from '../Header';
import Footer from '../Footer';
import Cookie from '../Cookie';
import theme from '../../theme';

import safariPinnedTab from '../../images/safari-pinned-tab.svg';
import favicon16x16 from '../../images/freysoft-ukraine-favicon-16.png';
import favicon48x48 from '../../images/freysoft-ukraine-favicon-48.png';
import * as styles from './Layout.module.scss';

const faviconLinks = [
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16x16 },
  { rel: 'shortcut icon', type: 'image/png', href: favicon48x48 },
  { rel: 'mask-icon', color: '#fff', href: safariPinnedTab },
];

type Props = {
  pageContext: {
    footerVariant: 'dark' | 'default';
    headerVariant: 'transparent' | 'default';
    noHeaderSpace: boolean;
    noLayout?: boolean;
  };
  location: Location;
  children: React.ReactNode;
};

const JSON_LD_SCRIPT = {
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: 'FreySoft',
  url: `${process.env.SITE_URL}/`,
  potentialAction: {
    '@type': 'SearchAction',
    // eslint-disable-next-line max-len
    target: `${process.env.SITE_URL}/search?query={search_term_string}{search_term_string}`,
    'query-input': 'required name=search_term_string',
  },
};

const JSON_LD_SCRIPT_ORGANIZATION = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'FreySoft',
  url: `${process.env.SITE_URL}/`,
  logo: `${process.env.SITE_URL}/freysoft-logo.svg`,
  alternateName: 'Freysoft',
  sameAs: [
    'https://www.linkedin.com/company/frey-soft',
    'https://www.facebook.com/freysoft/',
    'https://www.instagram.com/freysoft.inc/',
    'https://twitter.com/FreySoftInc',
  ],
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+38 063 252 22 21',
      contactType: 'customer service',
      email: 'info@freysoft.com',
      areaServed: 'UA',
      availableLanguage: ['uk', 'en', 'ru'],
    },
  ],
};

export default function Layout({ children, pageContext, location }: Props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const memoizedSnackContent = React.useCallback(
    (key, message) => <SnackContent id={key} message={message} />,
    []
  );

  const isHomePage = location.pathname === '/';
  const headerVariant = !trigger ? pageContext.headerVariant : 'default';

  if (pageContext.noLayout) {
    return children;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div
        className={clsx(styles.root, {
          [styles.noPadding]: pageContext.noHeaderSpace,
        })}
      >
        <Helmet link={faviconLinks}>
          <meta
            content="minimum-scale=1, initial-scale=1, width=device-width"
            name="viewport"
          />
          {isHomePage && (
            <script type="application/ld+json">
              {JSON.stringify(JSON_LD_SCRIPT)}
            </script>
          )}
          {isHomePage && (
            <script type="application/ld+json">
              {JSON.stringify(JSON_LD_SCRIPT_ORGANIZATION)}
            </script>
          )}
        </Helmet>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LazyMotion features={domAnimation}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                content={memoizedSnackContent}
                maxSnack={3}
              >
                <ModalProvider>
                  <Header variant={headerVariant} />
                  <div className={styles.content}>{children}</div>
                  <Footer variant={pageContext.footerVariant} />
                  <Cookie />
                </ModalProvider>
              </SnackbarProvider>
            </LazyMotion>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    </Suspense>
  );
}
