export const PL_PHONE_NUMBER = '+48795991686';
export const PL_FORMATTED_PHONE_NUMBER = '+48 795 991 686';
export const UA_PHONE_NUMBER = '+380632522221';
export const UA_FORMATTED_PHONE_NUMBER = '+38 063 252 22 21';
export const EMAIL = 'info@freysoft.com';

export const VIBER_URL =
  'https://wa.me/380632522221?text=Tell%20me%20more%20about%20your%20services';
export const TG_URL =
  // eslint-disable-next-line max-len
  'https://telegram.me/freysoft?text=Tell%20me%20more%20about%20your%20services';
