import { useState, useEffect, useCallback } from 'react';

const usePostsPagination = (allPosts = [], postsPerPage = 10) => {
  const [posts, setPosts] = useState([...allPosts.slice(0, postsPerPage)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(allPosts.length > postsPerPage);

  const handleLoadMore = useCallback(() => {
    setLoadMore(true);
  }, []);

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = posts.length;
      const isMore = currentLength < allPosts.length;
      const nextResults = isMore
        ? // @todo use settings from WP
          allPosts.slice(currentLength, currentLength + (postsPerPage + 1))
        : [];
      setPosts([...posts, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); // eslint-disable-line

  useEffect(() => {
    const isMore = posts.length < allPosts.length;
    setHasMore(isMore);
  }, [posts]); // eslint-disable-line

  return { posts, hasMore, handleLoadMore };
};

export default usePostsPagination;
