import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import isNull from 'lodash/isNull';
import clsx from 'clsx';
import { m, AnimatePresence } from 'framer-motion';
import * as styles from './HeaderMobileMenu.module.scss';
import arrow from '../../images/arrow-back.svg';
import HeaderMobileMenuItem from '../HeaderMobileMenuItem';
import { getBoundingClientRect } from '../../utils';
import { HeaderMenuItem } from '../Header';
import {
  INITIAL_ANIMATION_DELAY,
  INITIAL_DOM_RECT,
  SLIDE_UP_BEZIER_CURVE,
} from '../../constants';

type Props = {
  items: HeaderMenuItem[];
};

type State = {
  nestedMenuId: string | null;
  nestedMenuRect: Omit<DOMRect, 'toJSON'>;
  normalMenuRect: Omit<DOMRect, 'toJSON'>;
  computed: boolean;
};

const initialHeaderMobileMenuState = {
  nestedMenuId: null,
  nestedMenuRect: INITIAL_DOM_RECT,
  normalMenuRect: INITIAL_DOM_RECT,
  computed: false,
};

function HeaderMobileMenu({ items = [] }: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [state, setState] = React.useState<State>(initialHeaderMobileMenuState);
  const isOpenedNestedMenuOnce = React.useRef<boolean>(false);
  const isOpenedNestedMenu = !isNull(state.nestedMenuId);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = React.useCallback(() => {
    setOpen(false);
    isOpenedNestedMenuOnce.current = false;
  }, []);

  const handleNestedMenuClose = React.useCallback(() => {
    setState((prevState) => ({ ...prevState, nestedMenuId: null }));
  }, []);

  const handleGetNestedMenuTitleRect = React.useCallback((node) => {
    const boundingClientRect = getBoundingClientRect(node as HTMLElement);

    setState((prevState) => ({
      ...prevState,
      computed: true,
      nestedMenuRect: {
        ...prevState.nestedMenuRect,
        ...boundingClientRect,
      },
    }));
  }, []);

  const handleClick = React.useCallback(
    (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      id: string | null,
      isNestedList: boolean
    ) => {
      if (isNestedList) {
        const boundingClientRect = getBoundingClientRect(
          event.target as HTMLElement
        );
        isOpenedNestedMenuOnce.current = true;
        setState((prevState) => ({
          ...prevState,
          computed: false,
          nestedMenuId: id,
          normalMenuRect: {
            ...prevState.normalMenuRect,
            ...boundingClientRect,
          },
        }));
      } else {
        handleDrawerClose();
      }
    },
    [handleDrawerClose]
  );

  const variants = {
    active: {
      transition: { duration: 0.2, ease: 'easeInOut' },
      transform: 'translateY(0px)',
      opacity: 1,
      visibility: 'visible',
    },
    inactive: {
      transition: { duration: 0.2, ease: 'easeInOut' },
      opacity: 0,
      transitionEnd: {
        visibility: 'hidden',
      },
    },
  };

  if (isOpenedNestedMenuOnce.current) {
    variants.inactive.visibility = 'visible';
  } else {
    variants.inactive.visibility = 'hidden';
  }

  if (state.computed) {
    // eslint-disable-next-line max-len
    variants.inactive.transform = `translateY(${state.nestedMenuRect.height}px)`;
  }

  return (
    <>
      <m.div
        animate={{ opacity: 0.99 }}
        className={clsx(styles.burger, styles.navIcon)}
        initial={{ opacity: 0 }}
        layout
        onClick={handleDrawerOpen}
        onKeyPress={handleDrawerOpen}
        role="button"
        tabIndex={0}
        transition={{
          delay: INITIAL_ANIMATION_DELAY + 0.6,
          duration: 1.5,
          ease: SLIDE_UP_BEZIER_CURVE,
        }}
      >
        <span />
        <span />
        <span />
      </m.div>{' '}
      <SwipeableDrawer
        anchor="right"
        classes={{
          paper: clsx(styles.paper, {
            [styles.paperPrimary]: !isOpenedNestedMenu,
            [styles.paperSecondary]: isOpenedNestedMenu,
          }),
        }}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        open={open}
        SlideProps={{
          onExited: handleNestedMenuClose,
        }}
      >
        <button
          className={clsx(styles.close, {
            [styles.closeLight]: isOpenedNestedMenu,
          })}
          onClick={handleDrawerClose}
          type="button"
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 4L20 20"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              d="M20 4L4 20"
              stroke="currentColor"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </button>

        <m.div
          animate={isOpenedNestedMenu ? 'active' : 'inactive'}
          variants={variants}
        >
          <div
            className={styles.back}
            onClick={handleNestedMenuClose}
            onKeyPress={handleNestedMenuClose}
            role="button"
            tabIndex={0}
          >
            <button className={styles.backButton} type="button">
              <img alt="back icon" className={styles.backArrow} src={arrow} />
            </button>
            <span className={styles.backText}>Back</span>
          </div>
        </m.div>

        <List classes={{ root: styles.list }}>
          {items.map((item, i) => (
            <HeaderMobileMenuItem
              key={item.id}
              i={i}
              id={item.id}
              isOpenedNestedMenu={isOpenedNestedMenu}
              link={item.link}
              name={item.name}
              nestedMenuId={state.nestedMenuId}
              nestedMenuItems={item.children}
              onClick={(event, id, isNestedMenu) => {
                handleClick(event, id, isNestedMenu);

                if (item.onClick) {
                  item.onClick(event);
                }
              }}
              onGetNestedMenuTitleRect={handleGetNestedMenuTitleRect}
              {...item.extra}
            />
          ))}
        </List>

        <AnimatePresence>
          {isOpenedNestedMenu && state.computed && (
            <m.div
              animate={{ height: '100%', top: '50%' }}
              exit={{
                height: 0,
                top: state.normalMenuRect.top + state.normalMenuRect.height / 2,
              }}
              initial={{
                position: 'absolute',
                top: state.nestedMenuRect.top + state.nestedMenuRect.height / 2,
                left: '50%',
                width: '100%',
                height: 0,
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#000',
                zIndex: -1,
              }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            />
          )}
        </AnimatePresence>
      </SwipeableDrawer>
    </>
  );
}

export default HeaderMobileMenu;
