import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xxl: true;
  }
}

export const GREEN_NORMAL = '#2FDF84';
export const GREEN_LIGHT = '#00ffc4';
export const GREEN_DARK = '#18D396';

const theme = createTheme({
  palette: {
    primary: {
      dark: GREEN_DARK,
      main: GREEN_NORMAL,
      light: GREEN_LIGHT,
    },
    error: {
      main: '#E41A4B',
    },
  },
  // typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 768,
      lg: 992,
      xl: 1440,
      xxl: 1900,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiMenu: {
      paper: {
        border: '1px solid #CED4DA',
        maxHeight: 350,
        borderRadius: 8,
      },
    },
    // MuiCssBaseline: {
    //   '@global': {
    //     '@font-face': FONT_FACE,
    //   },
    // },
    MuiTab: {
      root: {
        minHeight: 0,
        minWidth: 0,
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 0,
      },
    },
    MuiCheckbox: {
      root: {
        color: 'white',
      },
    },
    MuiSelect: {
      root: {
        color: 'white',
      },
      icon: {
        color: 'white',
        opacity: 0.5,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 0,
      },
    },

    MuiMenuItem: {
      root: {
        minHeight: 0,
        fontSize: 16,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        backgroundColor: '#485563',
      },
    },
  },
});

export default theme;
