import React, { ForwardedRef, forwardRef } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { useModal } from 'mui-modal-provider';

import TextField from '../TextField';
import formConfig, {
  ContactUsFormFieldValues,
  ContactUsFormFieldNames,
  defaultValues,
} from './form-config';
import { COUNTRIES } from '../../constants';
// import FormAttachmentField from '../FormAttachmentField';
import { sendContactUsForm } from '../../services';
import { ga } from '../../utils';
import { ExtendedError } from '../../types';
import * as styles from './ContactUsForm.module.scss';
import ThanksModal from '../ThanksModal';

export interface ContactUsFormProps {
  colorSchema?: 'dark' | 'light';
  onClose?: () => void;
}

function ContactUsForm(
  { onClose, colorSchema = 'dark' }: ContactUsFormProps,
  ref: ForwardedRef<HTMLFormElement>
): JSX.Element {
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<ContactUsFormFieldValues>(formConfig);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    setValue,
    setError,
  } = methods;

  const handleOnSubmit = handleSubmit(
    async ({
      fullName,
      phone,
      company,
      email,
      message,
      country,
      attachment: files,
      subscribe,
    }: ContactUsFormFieldValues) => {
      try {
        // ga('send', 'event', 'forms', 'submit', 'contact-us-form');
        await sendContactUsForm({
          email,
          phone,
          name: fullName,
          company,
          message,
          country,
          attachment: files !== null ? files[0] : undefined,
          subscribe,
        });
        reset(defaultValues);
        onClose();
        const upperTimeoutId = setTimeout(() => {
          const modal = showModal(ThanksModal);
          clearTimeout(upperTimeoutId);
          const innerTimeoutId = setTimeout(() => {
            modal.hide();
            clearTimeout(innerTimeoutId);
          }, 3000);
        }, 300);
      } catch (e) {
        const error = e as ExtendedError;
        if (error.errors) {
          (error.errors as Error[]).forEach(({ message: errorMessage }) =>
            enqueueSnackbar(
              { message: errorMessage, variant: 'error' },
              { persist: false }
            )
          );
        } else {
          enqueueSnackbar(
            {
              message: error.message || 'Something went wrong',
              variant: 'error',
            },
            { persist: false }
          );
        }
      }
    }
  );

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event) {
      // ga(
      //   'send',
      //   'event',
      //   'forms',
      //   `${event.target.name}-field`,
      //   'contact-us-form'
      // );
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        ref={ref}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        onSubmit={handleOnSubmit}
      >
        <div style={{ position: 'absolute', height: 0, overflow: 'hidden' }}>
          Username <input name="fake_safari_username" type="text" />
          Password <input name="fake_safari_password" type="password" />
        </div>
        <Grid container spacing={{ xs: 3.5, md: 2 }}>
          <Grid item md={6} xs={12}>
            <div className={styles.inputField}>
              <Controller
                control={control}
                name={ContactUsFormFieldNames.FULL_NAME}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => (
                  <TextField
                    colorSchema={colorSchema}
                    disabled={isSubmitting}
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    id="contact-us-full-name-field"
                    label="Full Name"
                    name={name}
                    onChange={onChange}
                    onFocus={handleFocus}
                    required
                    type="text"
                    value={value}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={styles.inputField}>
              <Controller
                control={control}
                name={ContactUsFormFieldNames.EMAIL}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => (
                  <TextField
                    colorSchema={colorSchema}
                    disabled={isSubmitting}
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    id="contact-us-email-field"
                    label="Email"
                    name={name}
                    onChange={onChange}
                    onFocus={handleFocus}
                    required
                    type="email"
                    value={value}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={styles.inputField}>
              <Controller
                control={control}
                name={ContactUsFormFieldNames.PHONE}
                render={({
                  field: { value, onChange, name },
                  fieldState: { error },
                }) => (
                  <TextField
                    colorSchema={colorSchema}
                    disabled={isSubmitting}
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    id="contact-us-phone-field"
                    label="Phone number"
                    name={name}
                    onChange={onChange}
                    onFocus={handleFocus}
                    required
                    type="tel"
                    value={value}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={styles.inputField}>
              <Controller
                control={control}
                name={ContactUsFormFieldNames.COUNTRY}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <TextField
                    colorSchema={colorSchema}
                    disabled={isSubmitting}
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    id="contact-us-country-field"
                    label="Country"
                    name={name}
                    onChange={onChange}
                    onFocus={handleFocus}
                    required
                    select
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        PaperProps: { style: { maxHeight: 350 } },
                      },
                    }}
                    value={value}
                  >
                    {COUNTRIES.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    ).map((country) => (
                      <MenuItem key={country.code} value={country.label}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inputField}>
              <Controller
                control={control}
                name={ContactUsFormFieldNames.COMPANY}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <TextField
                    colorSchema={colorSchema}
                    disabled={isSubmitting}
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    id="contact-us-company-field"
                    label="Company"
                    name={name}
                    onChange={onChange}
                    onFocus={handleFocus}
                    required
                    type="text"
                    value={value}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.inputField}>
              <Controller
                control={control}
                name={ContactUsFormFieldNames.MESSAGE}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <TextField
                    colorSchema={colorSchema}
                    disabled={isSubmitting}
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    id="contact-us-message-field"
                    label="Message"
                    multiline
                    name={name}
                    onChange={onChange}
                    onFocus={handleFocus}
                    required
                    value={value}
                  />
                )}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <FormAttachmentField
              control={control}
              errors={errors}
              name={ContactUsFormFieldNames.ATTACHMENT}
              reset={reset}
              setError={setError}
              setValue={setValue}
              text="Maximum file size: 10MB"
            />
          </Grid> */}
          <Grid className={styles.checkboxContainer} container item spacing={0}>
            <Grid item>
              <FormControlLabel
                className={styles.checkboxField}
                control={
                  <Checkbox
                    className={clsx(
                      styles.checkboxIcon,
                      colorSchema === 'light' && styles.checkboxIconDark
                    )}
                    color="primary"
                    disableRipple
                  />
                }
                disabled={isSubmitting}
                label="I want to receive news and updates once in a while"
                labelPlacement="end"
                onChange={(e, checked) =>
                  setValue(ContactUsFormFieldNames.SUBSCRIBE, checked)
                }
                value="end"
              />
            </Grid>
            <Grid item>
              <p
                className={clsx(
                  styles.checkboxDescription,
                  colorSchema === 'light' && styles.checkboxDescriptionDark
                )}
              >
                We will add your info to our CRM for contacting you regarding
                your request. For more info please consult our{' '}
                <a href="/privacy-policy">privacy policy</a>.
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <button
              className={clsx('button-primary', styles.send)}
              disabled={isSubmitting}
              type="submit"
            >
              Send Message
            </button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default forwardRef(ContactUsForm);
