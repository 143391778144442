import React from 'react';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const colorSchemas = {
  light: {
    label: 'rgba(0, 0, 0, 0.5)',
    text: '#000000',
    underline: 'rgba(0, 0, 0, 0.3)',
    error: '#F45659',
    icon: '#000000',
  },
  dark: {
    label: 'rgba(255, 255, 255, 0.5)',
    text: 'rgba(255, 255, 255, 1)',
    underline: 'rgba(255, 255, 255, 0.3)',
    error: '#F45659',
    icon: '#808993',
  },
};

const getColorFromSchema = (
  key: keyof typeof colorSchemas.light,
  schema: keyof typeof colorSchemas = 'light'
) => colorSchemas[schema][key];

type FieldProps = TextFieldProps & {
  colorSchema?: keyof typeof colorSchemas;
};

const Field = styled(MuiTextField, {
  shouldForwardProp: (props) => props !== 'colorSchema',
})<FieldProps>(({ colorSchema }) => {
  return {
    // Base Styles
    fontFamily: 'HelveticaNowDisplay, sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: 23.52,

    // Input
    '& .MuiInputBase-input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: colorSchema === 'dark' && '0 0 0 500px #020213 inset',
        WebkitTextFillColor: colorSchema === 'dark' && '#fff',
      },
      fontFamily: 'HelveticaNowDisplay, Helvetica, sans-serif',
      color: getColorFromSchema('text', colorSchema),
    },

    // Label
    '& label.Mui-focused:not(.Mui-error)': {
      color: getColorFromSchema('label', colorSchema),
    },
    '& .MuiFormLabel-root:not(.Mui-error)': {
      color: getColorFromSchema('label', colorSchema),
    },

    // Underline
    '& .MuiInput-underline:before': {
      borderBottomColor: getColorFromSchema('underline', colorSchema),
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: getColorFromSchema('underline', colorSchema),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: getColorFromSchema('underline', colorSchema),
    },

    // Select
    '& .MuiSelect-icon': {
      color: getColorFromSchema('icon', colorSchema),
    },
  };
});

const TextField = React.forwardRef<HTMLInputElement, FieldProps>(
  ({ className, colorSchema = 'light', ...otherProps }, ref) => {
    return (
      <Field
        className={className}
        colorSchema={colorSchema}
        variant="standard"
        ref={ref}
        {...otherProps}
      />
    );
  }
);

export default TextField;
