import React from 'react';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import { m } from 'framer-motion';

import { HeaderMenuItem } from '../Header';
import * as styles from './HeaderDesktopMenu.module.scss';
import useInViewAnimation from '../../hooks/useInViewAnimation';
import { containerVariant, slideRightVariant, slideUpVariant } from '../../utils/animation';
import { Link } from 'gatsby';

const MotionLink = m(Link);

type MenuProps = {
  items: HeaderMenuItem[];
};

export default function HeaderDesktopMenu({ items = [] }: MenuProps) {
  const { ref: containerRef, control: containerControl } = useInViewAnimation();
  const { ref: itemRef, control: itemControl } = useInViewAnimation();
  const [dropdownMenuId, setDropdownMenuId] = React.useState<string | null>(
    null
  );
  const location = useLocation();
  const checkActiveLink = (currentLocation: Location, slug: string) =>
    currentLocation.href.includes(`/${slug.toLowerCase()}`);

  const handleMenuOpen = (id: string) => () => {
    setDropdownMenuId(id);
    itemControl.start('visible');
  };

  const handleMenuClose = () => {
    setDropdownMenuId(null);
    itemControl.start('hidden');
  };

  const renderMenuItem = (item: HeaderMenuItem, index: number) => {
    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      if (item.onClick) {
        item.onClick(event);
      }
    };

    if (!item.children || item.children.length === 0) {
      return (
        <MotionLink
          key={item.id}
          className={clsx(styles.item, {
            [styles.activeLink]: checkActiveLink(location, item.name),
          })}
          onClick={handleClick}
          to={item.link}
          variants={slideUpVariant('5%')}
        >
          {item.name}
        </MotionLink>
      );
    }

    const isDropdownMenuOpen = item.id === dropdownMenuId;

    return (
      <m.div
        key={item.id}
        className={styles.wrap}
        onMouseLeave={handleMenuClose}
      >
        <m.button
          key={item.id}
          className={clsx(styles.item, {
            [styles.itemOpen]: isDropdownMenuOpen,
            [styles.activeLink]: checkActiveLink(location, item.name),
          })}
          onClick={handleMenuOpen(item.id)}
          type="button"
          variants={slideUpVariant('5%')}
        >
          {item.name}
        </m.button>
        <m.div
          className={clsx(styles.drop, { [styles.open]: isDropdownMenuOpen })}
        >
          {item.children.map((child, j) => (
            <MotionLink
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${j}`}
              className={styles.menuItem}
              onClick={(event) => {
                handleClick(event);
                handleMenuClose();
              }}
              to={child.link}
              ref={itemRef}
              animate={itemControl}
              initial="hidden"
              variants={slideRightVariant("100%", { delay: 0.04 * j })}
            >
              {child.name}
            </MotionLink>
          ))}
        </m.div>
      </m.div>
    );
  };

  return (
    <m.nav
      className={styles.root}
      ref={containerRef}
      animate={containerControl}
      initial="hidden"
      variants={containerVariant()}
    >
      {items.map(renderMenuItem)}
    </m.nav>
  );
}
