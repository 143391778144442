import React from 'react';
import clsx from 'clsx';
import ListItem from '@mui/material/ListItem';
import { AnimatePresence, m, usePresence, MotionProps } from 'framer-motion';
import Marquee from 'react-fast-marquee';

import * as styles from './HeaderMobileMenuItem.module.scss';
import { Link } from 'gatsby';

type MenuItem = {
  id: string;
  link: string;
  name: string;
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

type Props = {
  i: number;
  id: string;
  name: string;
  link: string;
  isOpenedNestedMenu: boolean;
  /**
   * Parent ID of the menu items
   */
  nestedMenuId: string | null;
  nestedMenuItems: MenuItem[];
  onGetNestedMenuTitleRect: (element: HTMLElement) => void;
  onClick: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: string | null,
    isNestedList: boolean
  ) => void;
  marqueeSpeed?: number;
};

function HeaderMobileMenuItem({
  i,
  id,
  name,
  link,
  isOpenedNestedMenu,
  nestedMenuId,
  nestedMenuItems,
  onGetNestedMenuTitleRect,
  onClick,
  marqueeSpeed = 20,
}: Props) {
  const [isPresent, safeToRemove] = usePresence();
  const isNestedList = nestedMenuItems && nestedMenuItems.length > 0;
  const isNestedMenuTitle = isOpenedNestedMenu && id === nestedMenuId;

  // a table containing the element's index property and its width
  const [marqueeItems, setMarqueeItems] = React.useState<{
    [index: number]: number;
  }>({});

  const menuRef = React.useCallback(
    (node: HTMLDivElement) => {
      if (node && isNestedMenuTitle) {
        onGetNestedMenuTitleRect(node);
      }
    },
    [onGetNestedMenuTitleRect, isNestedMenuTitle]
  );

  const spanRef = (node: HTMLSpanElement) => {
    if (node && !marqueeItems[i]) {
      setMarqueeItems((prevState) => ({
        ...prevState,
        [i]: Math.ceil(window.innerWidth / node.getBoundingClientRect().width),
      }));
    }
  };

  const menuItemAnimation: MotionProps = {
    layout: true,
    initial: 'out',
    style: {
      position: isPresent ? 'static' : 'absolute',
    },
    animate: isPresent ? 'in' : 'out',
    whileTap: 'tapped',
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0, zIndex: -1 },
      tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.1 } },
    },
    onAnimationComplete: () => !isPresent && safeToRemove && safeToRemove(),
    transition: { type: 'spring', stiffness: 800, damping: 50, mass: 1 },
  };

  return (
    <>
      <m.li
        {...menuItemAnimation}
        className={clsx({
          [styles.title]: isNestedMenuTitle,
          [styles.hide]: isOpenedNestedMenu && id !== nestedMenuId,
        })}
      >
        <div ref={menuRef} className={styles.root}>
          <ListItem
            button={!isOpenedNestedMenu && isNestedList}
            disableGutters
            {...(!isNestedList && {
              component: Link,
              to: link,
              partiallyActive: true,
            })}
            classes={{
              root: styles.link,
              button: styles.button,
            }}
            {...(!isOpenedNestedMenu && {
              onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                if (onClick) {
                  onClick(event, id, isNestedList);
                }
              },
            })}
          >
            <Marquee gradient={false} speed={marqueeSpeed}>
              <span>{name}</span>
              <span ref={spanRef} className={styles.highlight}>
                {name}
              </span>
              {marqueeItems[i] > 0 &&
                Array.from({ length: marqueeItems[i] - 1 }).map((_, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={j}>{name}</span>
                ))}
            </Marquee>
          </ListItem>
        </div>
      </m.li>
      {isNestedList &&
        nestedMenuId === id &&
        nestedMenuItems.map((nestedMenuItem, j) => {
          const nestedMenuItemAnimation = {
            variants: {
              show: {
                opacity: 1,
                y: 0,
                transition: { duration: 0.2 },
              },
              hide: {
                opacity: 0,
                y: -80,
              },
            },
            initial: 'hide',
            animate: 'show',
            exit: 'hide',
          };
          return (
            <AnimatePresence key={nestedMenuItem.id}>
              <m.li
                {...nestedMenuItemAnimation}
                className={clsx({
                  [styles.menuItem]: id === nestedMenuId,
                })}
              >
                <div>
                  <ListItem
                    disableGutters
                    component={Link}
                    to={nestedMenuItem.link}
                    onClick={(event) => {
                      if (nestedMenuItem.onClick) {
                        nestedMenuItem.onClick(event);
                      }

                      if (onClick) {
                        onClick(event, nestedMenuItem.id, false);
                      }
                    }}
                  >
                    <span>{nestedMenuItem.name}</span>
                  </ListItem>
                  {j < nestedMenuItems.length - 1 && (
                    <div className={styles.separatorContainer}>
                      <div
                        className={styles.separator}
                        style={{ animationDelay: `${0.05 * j}s` }}
                      />
                    </div>
                  )}
                </div>
              </m.li>
            </AnimatePresence>
          );
        })}
    </>
  );
}

export default React.memo(HeaderMobileMenuItem);
