import React, { useState } from 'react';
import { m, AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';
import store from 'store';
import clsx from 'clsx';
import * as styles from './Cookie.module.scss';
import {
  INITIAL_ANIMATION_DELAY,
  SLIDE_UP_BEZIER_CURVE,
} from '../../constants';
import cookieIcon from '../../images/cookie.svg';

export default function Cookie() {
  const [isConfirmed, setConfirmed] = useState<boolean>(() =>
    store.get('cookie-read')
  );

  const handleConfirm = () => {
    store.set('cookie-read', true);
    setConfirmed(true);
  };

  const message = (
    <div className={clsx(styles.notificationContent, styles.cookieText)}>
      <strong>We use cookies to ensure your best experience.&nbsp;</strong>
      <span>
        Through your continued use of this site you accept this use. You may
        delete and block all cookies from this site as described{' '}
        <a href="https://www.aboutcookies.org">here</a>. For more information,
        please see our <Link to="/cookie-policy">cookie policy</Link>.
      </span>
    </div>
  );

  return (
    <AnimatePresence>
      {!isConfirmed && (
        <m.div
          animate={{ y: 0, x: '-50%', opacity: 0.99 }}
          className={clsx(styles.notification, styles.cookies)}
          exit={{
            y: 100,
            x: '-50%',
            opacity: 0,
            transition: {
              duration: 0.75,
              delay: 0,
              ease: SLIDE_UP_BEZIER_CURVE,
            },
          }}
          initial={{ y: 50, x: '-50%', opacity: 0 }}
          transition={{
            duration: 1.5,
            delay: INITIAL_ANIMATION_DELAY + 1.25,
            ease: SLIDE_UP_BEZIER_CURVE,
          }}
        >
          <div
            className={clsx(styles.gridContainer, styles.gridContainerCookie)}
          >
            <div>
              <img
                alt="Cookie"
                className={styles.cookieImage}
                src={cookieIcon}
              />
            </div>
            {message}
            <button
              className={styles.cookieBtn}
              onClick={handleConfirm}
              onKeyPress={handleConfirm}
              tabIndex={0}
              type="button"
            >
              Acknowledged
            </button>
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
}
