import React from 'react';
import { Modal, ModalProps } from '@mui/material';
import { m } from 'framer-motion';
import clsx from 'clsx';
import useInViewAnimation from '../../hooks/useInViewAnimation';
import ContactUsForm from '../ContactUsForm';
import { slideUpVariant } from '../../utils/animation';
import { CloseModalIcon } from '../../icons';
import * as styles from './ContactUsModal.module.scss';
import ContactUsBanner from '../ContactUsBanner';

const MotionContactUsForm = m(ContactUsForm);

export type ContactUsModalProps = Omit<ModalProps, 'children'>;

export default function ContactUsModal({
  onClose,
  ...otherProps
}: ContactUsModalProps) {
  const { ref: headingRef, control: headingControl } = useInViewAnimation();
  const { ref: separatorRef, control: separatorControl } = useInViewAnimation();
  const { ref: formRef, control: formControl } = useInViewAnimation();

  const closeModal = () => onClose({}, 'backdropClick');

  return (
    <Modal onClose={onClose} {...otherProps} className={styles.modalRoot}>
      <div className={styles.wrapper}>
        <div className={styles.modal}>
          <m.h2
            className={clsx(styles.title)}
            ref={headingRef}
            animate={headingControl}
            initial="hidden"
            variants={slideUpVariant('0')}
          >
            Contact us
          </m.h2>
          <ContactUsBanner 
            transition={slideUpVariant('0', { delay: 0.1 })}
          />
          <m.div
            className={styles.separator}
            ref={separatorRef}
            animate={separatorControl}
            initial="hidden"
            variants={slideUpVariant('0', { delay: 0.2 })}
          >
            <div className={styles.separatorLine} />
            <p
              className={styles.separatorTitle}
            >
              Or fill out the form and we will contact you
            </p>
            <div className={styles.separatorLine} />
          </m.div>
          <MotionContactUsForm
            ref={formRef}
            animate={formControl}
            initial="hidden"
            variants={slideUpVariant('0', { delay: 0.3 })}
            colorSchema="light"
            onClose={closeModal}
          />
          <button
            type="button"
            className={styles.modalClose}
            onClick={closeModal}
          >
            <CloseModalIcon />
          </button>
        </div>
      </div>
    </Modal>
  );
}
