export const LIMITED_EXTENSIONS: string[] = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'application/x-empty',
  'text/pdf',
  'text/plain',
];

export const DEFAULT_EXTENSIONS: string[] = [
  ...LIMITED_EXTENSIONS,
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const validateFileExtensions = (
  files: FileList,
  exts: string[] = DEFAULT_EXTENSIONS,
): boolean =>
  !files || files.length === 0 || (files[0] && exts.includes(files[0].type));

export const validateFileSize = (
  files: FileList,
  maxFileSizeInMb = 5,
): boolean =>
  !files || files.length === 0 || files[0].size / 1024 / 1024 < maxFileSizeInMb;