import React from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import clsx from 'clsx';
import { m } from 'framer-motion';
import * as styles from './SnackContent.module.scss';
import checkIcon from '../../images/check.svg';
import snackCloseIcon from '../../images/snackclose.svg';
import uncheckIcon from '../../images/uncheck.svg';
import cookieIcon from '../../images/cookie.svg';
import {
  INITIAL_ANIMATION_DELAY,
  SLIDE_UP_BEZIER_CURVE,
} from '../../constants';

const MotionSnackbarContent = m(SnackbarContent);

export interface SnackContentProps {
  id: string | number;
  message: {
    variant: 'error' | 'success' | 'cookie';
    message: React.ReactNode | string;
  };
}

const renderImage = (variant: string) => {
  if (variant === 'error') {
    return uncheckIcon;
  }

  if (variant === 'cookie') {
    return cookieIcon;
  }

  return checkIcon;
};

const SnackContent = React.forwardRef<HTMLDivElement, SnackContentProps>(
  ({ message: { message, variant }, id }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = React.useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const isError = variant === 'error';

    return (
      <MotionSnackbarContent
        animate={{ y: 0, opacity: 0.99 }}
        className={clsx(styles.snack, {
          [styles.cookies]: variant === 'cookie',
        })}
        exit={{
          visibility: 'hidden',
          transition: { delay: 0, duration: 0 },
        }}
        initial={{ y: 50, opacity: 0 }}
        transition={{
          duration: 1.5,
          delay: INITIAL_ANIMATION_DELAY + 1.25,
          ease: SLIDE_UP_BEZIER_CURVE,
        }}
      >
        <div
          ref={ref}
          className={clsx(styles.gridContainer, {
            [styles.gridContainerCookie]: variant === 'cookie',
          })}
        >
          <div>
            <img
              alt={isError ? 'Uncheked' : 'Checked'}
              className={styles.cookieImage}
              src={renderImage(variant)}
            />
          </div>
          <div
            className={clsx(styles.snackContent, {
              [styles.cookieText]: variant === 'cookie',
            })}
          >
            {message}
          </div>
          {variant === 'error' ? (
            <div>
              <span
                className={styles.snackClose}
                onClick={handleDismiss}
                onKeyPress={handleDismiss}
                role="button"
                tabIndex={0}
              >
                <img alt="Close" src={snackCloseIcon} />
              </span>
            </div>
          ) : (
            <button
              className={styles.cookieBtn}
              onClick={handleDismiss}
              onKeyPress={handleDismiss}
              tabIndex={0}
              type="button"
            >
              Acknowledged
            </button>
          )}
        </div>
      </MotionSnackbarContent>
    );
  }
);

SnackContent.displayName = 'SnackContent';

export default SnackContent;
