import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery, Theme } from '@mui/material';
import { m, Variants } from 'framer-motion';
import { useModal } from 'mui-modal-provider';

import MobileMenu from '../HeaderMobileMenu';
import DesktopMenu from '../HeaderDesktopMenu';
import Container from '../Container';
import contactIcon from '../../images/contactUs.svg';
import freysoftLogoText from '../../images/freysoft-logo-text.svg';
import { ga } from '../../utils';
import { PL_PHONE_NUMBER } from '../ContactUs/constants';
import ContactUsModal from '../ContactUsModal';
import useInViewAnimation from '../../hooks/useInViewAnimation';
import { items } from './constants';
import * as styles from './Header.module.scss';

const LOGO_VARIANTS: Variants = {
  visible: {
    opacity: 1,
    translateY: '0%',
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  hidden: {
    translateY: '20%',
    opacity: 0,
  },
};

const LOGO_MOB_VARIANTS: Variants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  hidden: {
    opacity: 0,
  },
};

const CONTACT_US_BUTTON_VARIANTS: Variants = {
  visible: {
    opacity: 1,
    translateY: '0%',
    transition: {
      delay: 0.4,
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  hidden: {
    translateY: '20%',
    opacity: 0,
  },
};

const CONTACT_US_BUTTON_MOB_VARIANTS: Variants = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.4,
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  hidden: {
    opacity: 0,
  },
};

type HeaderProps = {
  variant?: string;
};

export default function Header({ variant }: HeaderProps) {
  const { showModal } = useModal();
  const isUpXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const { ref: refLogo, control: controlLogo } = useInViewAnimation();
  const { ref: refContactUs, control: controlContactUs } = useInViewAnimation();
  const { ref: refButton, control: controlButton } = useInViewAnimation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={clsx(styles.root, {
        [styles.transparent]: variant === 'transparent',
      })}
    >
      <Container>
        <div className={styles.bar}>
          <m.a className={styles.logoLink} href="/">
            <m.img
              ref={refLogo}
              alt="freysoft logo"
              animate={controlLogo}
              className={styles.logo}
              height={isUpXl ? '40' : '37'}
              initial="hidden"
              src={freysoftLogoText}
              variants={isUpXl ? LOGO_VARIANTS : LOGO_MOB_VARIANTS}
            />
          </m.a>
          {isUpXl ? (
            <DesktopMenu items={items} />
          ) : (
            <div className={styles.navIcons}>
              <m.a
                ref={refContactUs}
                animate={controlContactUs}
                className={styles.navIcon}
                href={`tel:${PL_PHONE_NUMBER}`}
                initial="hidden"
                onClick={() => {
                  // ga('send', 'event', 'links', 'click', 'header-mobile');
                }}
                variants={CONTACT_US_BUTTON_MOB_VARIANTS}
              >
                <img
                  alt="contact us"
                  className={styles.navIconPhone}
                  src={contactIcon}
                />
              </m.a>
              <MobileMenu items={items} />
            </div>
          )}

          <m.button
            ref={refButton}
            animate={controlButton}
            className={styles.contactUs}
            initial="hidden"
            type="button"
            variants={CONTACT_US_BUTTON_VARIANTS}
            onClick={() => {
              // ga('send', 'event', 'links', 'click', 'contact-us');
              showModal(ContactUsModal);
            }}
          >
            Contact us
          </m.button>
        </div>
      </Container>
    </div>
  );
}
