import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CloseModalIcon({ 
  sx, 
  stroke = "black",
  fill = "#D5DBE1",
  ...otherProps 
}: SvgIconProps) {
  return (
    <SvgIcon sx={{ color: 'transparent', ...sx }} {...otherProps}>
      <path
        d="M12 22.0005C17.5228 22.0005 22 17.5233 22 12.0005C22 6.47764 17.5228 2.00049 12 2.00049C6.47715 2.00049 2 6.47764 2 12.0005C2 17.5233 6.47715 22.0005 12 22.0005Z"
        fill={fill}
      />
      <path
        d="M14.7998 8.79956L8.7998 14.7996M8.7998 8.79956L14.7998 14.7996"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
