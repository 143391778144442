// extracted by mini-css-extract-plugin
export var container = "Footer-module--container--baf21";
export var copyright = "Footer-module--copyright--7cbe6";
export var copyrightDark = "Footer-module--copyright-dark--cd3bf";
export var copyrightWrap = "Footer-module--copyright-wrap--ca41c";
export var dark = "Footer-module--dark--fd1e0";
export var infoItem = "Footer-module--info-item--3c212";
export var infoItemDark = "Footer-module--info-item-dark--79800";
export var infoLinks = "Footer-module--info-links--04840";
export var infoLinksDark = "Footer-module--info-links-dark--af1ec";
export var infoWrap = "Footer-module--info-wrap--c24b8";
export var infoWrapDark = "Footer-module--info-wrap-dark--4d87c";
export var links = "Footer-module--links--31416";
export var mediaIcons = "Footer-module--media-icons--4b8c5";
export var mediaWrapDark = "Footer-module--media-wrap-dark--ef2d7";
export var root = "Footer-module--root--ee127";
export var wrap = "Footer-module--wrap--50a3e";