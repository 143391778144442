import { ga } from '../../utils';
import uniqueId from 'lodash/uniqueId';

export interface ExtraProps {
  marqueeSpeed?: number;
}

export interface HeaderMenuItemBase {
  id: string;
  name: string;
  link: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  native?: boolean;
  extra?: ExtraProps;
}

export interface HeaderMenuItem extends HeaderMenuItemBase {
  children?: HeaderMenuItemBase[];
}

export const items: HeaderMenuItem[] = [
  // {
  //   id: uniqueId(),
  //   name: 'Company',
  //   link: '/',
  //   onClick: () => {
  //     ga('send', 'event', 'links', 'click', 'company');
  //   },
  //   extra: {
  //     marqueeSpeed: 24,
  //   },
  // },
  {
    id: uniqueId(),
    name: 'Expertise',
    link: '/expertise/',
    extra: {
      marqueeSpeed: 28,
    },
    children: [
      {
        id: uniqueId(),
        name: 'Front end development',
        link: '/expertise/frontend-development-services/',
      },
      {
        id: uniqueId(),
        name: 'Back end development',
        link: '/expertise/backend-development-services/',
      },
      {
        id: uniqueId(),
        name: 'Full stack development',
        link: '/expertise/fullstack-development-services/',
      },
      {
        id: uniqueId(),
        name: 'Cloud First',
        link: '/expertise/cloud-first-development-services/',
      },
      {
        id: uniqueId(),
        name: 'Mobile App Development',
        link: '/expertise/mobile-app-development-services/',
      },
      {
        id: uniqueId(),
        name: 'Web development',
        link: '/expertise/web-development-services/',
      },
      {
        id: uniqueId(),
        name: 'UI/UX Design',
        link: '/expertise/ui-ux-design/',
      },
    ],
  },
  {
    id: uniqueId(),
    name: 'Services',
    link: '/services/',
    onClick: () => {
      // ga('send', 'event', 'links', 'click', 'services');
    },
    extra: {
      marqueeSpeed: 32,
    },
    children: [
      {
        id: uniqueId(),
        name: 'Third-party integrations',
        link: '/services/third-party-integrations/',
      },
      {
        id: uniqueId(),
        name: 'Application development and maintenance',
        link: '/services/application-development-and-maintenance/',
      },
      {
        id: uniqueId(),
        name: 'Product development service',
        link: '/services/product-development-service/',
      },
      {
        id: uniqueId(),
        name: 'Legacy Transformation, Cloud Migration',
        link: '/services/legacy-transformation/',
      },
      {
        id: uniqueId(),
        name: 'Delivery team as a subscription service',
        link: '/services/delivery-team-as-a-subscription/',
      },
      {
        id: uniqueId(),
        name: 'Staff augmentation',
        link: '/services/staff-augmentation/',
      },
    ],
  },
  {
    id: uniqueId(),
    name: 'Cases',
    link: '/cases/',
    onClick: () => {
      // ga('send', 'event', 'links', 'click', 'cases');
    },
    extra: {
      marqueeSpeed: 24,
    },
  },
  {
    id: uniqueId(),
    name: 'Industries',
    link: '/industries/',
    onClick: () => {
      // ga('send', 'event', 'links', 'click', 'industries');
    },
    extra: {
      marqueeSpeed: 32,
    },
    children: [
      {
        id: uniqueId(),
        name: 'FinTech',
        link: '/industries/fin-tech/',
      },
      {
        id: uniqueId(),
        name: 'Healthcare',
        link: '/industries/healthcare/',
      },
      {
        id: uniqueId(),
        name: 'Ecommerce',
        link: '/industries/e-commerce/',
      },
      {
        id: uniqueId(),
        name: 'Telecom',
        link: '/industries/telecom/',
      },
      {
        id: uniqueId(),
        name: 'Information Technology',
        link: '/industries/information-technology/',
      },
      {
        id: uniqueId(),
        name: 'Manufacturing',
        link: '/industries/manufacturing/',
      },
      {
        id: uniqueId(),
        name: 'Hospitality and Leisure',
        link: '/industries/hospitality-and-leisure/',
      },
      {
        id: uniqueId(),
        name: 'Insurance',
        link: '/industries/insurance/',
      },
      {
        id: uniqueId(),
        name: 'Logistics',
        link: '/industries/logistics/',
      },
    ],
  },
  {
    id: uniqueId(),
    name: 'Careers',
    link: '/careers/',
    onClick: () => {
      // ga('send', 'event', 'links', 'click', 'careers');
    },
    extra: {
      marqueeSpeed: 20,
    },
  },
  {
    id: uniqueId(),
    name: 'Blog',
    link: '/blog/',
    onClick: () => {
      // ga('send', 'event', 'links', 'click', 'blog');
    },
    extra: {
      marqueeSpeed: 36,
    },
  },
  // {
  //   id: uniqueId(),
  //   name: 'Pricing',
  //   link: '/pricing/',
  //   onClick: () => {
  //     ga('send', 'event', 'links', 'click', 'pricing');
  //   },
  //   extra: {
  //     marqueeSpeed: 20,
  //   },
  //   children: [
  //     {
  //       id: uniqueId(),
  //       name: 'Get your project estimation',
  //       link: '/pricing/project-estimation/',
  //     },
  //     {
  //       id: uniqueId(),
  //       name: 'Get rates for your team augmentation',
  //       link: '/pricing/team-augmentation/',
  //     },
  //   ],
  // },
];

export enum BannerEntries {
  BANNER_KEY = 'banner',
  BANNER_VALUE = 'hide',
}
